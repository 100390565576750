import {
  createStyles,
  makeStyles,
  Theme,
} from '@material-ui/core';

export const MainJSS = makeStyles((theme: Theme) => createStyles({
  header: {
    width: '50%',
    margin: 'auto',
    padding: `${theme.spacing(3)}px`,
  },
}));
