import React, {
  useContext,
  useState,
} from 'react';
import { Link } from 'react-router-dom';
import {
  TextField,
  Button,
  Typography,
} from '@material-ui/core';

import { RoutePath } from 'common/Types';

import { MainContext } from '../Main.context';

const SearchComponent = (): JSX.Element => {
  const mainContext = useContext(MainContext);
  const [textfieldValue, settextfieldValue] = useState<string>('');

  const getResults = (query: string): void => {
    // Ideally I'd like to separate the key here into a private env file, but skipping for time's sake
    fetch(`https://api.giphy.com/v1/gifs/search?api_key=tzHq4CDkM4C9DA68wUM6trTESJnVDDvm&q=${query}`)
      .then((res) => res.json())
      .then(
        (res) => {
          mainContext.setState({ results: res.data });
        },
        (err: string) => {
          mainContext.setState({
            isError: true,
            error: err,
          });
        },
      );
  };

  return (
    <>
      <form>
        <TextField
          variant="outlined"
          value={textfieldValue}
          onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => settextfieldValue(e.target.value)}
          onKeyDown={(e: React.KeyboardEvent) => {e.key === 'Enter' && e.preventDefault()}}
          fullWidth
        />
        <Button
          component={Link}
          to={RoutePath.results}
          type="submit"
          variant="contained"
          color="primary"
          fullWidth
          onClick={() => getResults(textfieldValue)}
        >
          <Typography>Search for Gifs</Typography>
        </Button>
      </form>
    </>
  );
};

export { SearchComponent };
