import React from 'react';
import { HashRouter as Router } from 'react-router-dom';

import { AppWrapperProps } from './AppWrapper.types';

export const AppWrapper = (props: AppWrapperProps): JSX.Element => {
  const { children } = props;

  return (
      <Router>
        {children}
      </Router>
  );
};
