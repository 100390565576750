import React from 'react';

import { MainContextProps } from './Main.types';
import { MainContext } from './Main.context';
import { MainComponent } from './Main.component';

export class MainContainer extends React.Component<unknown, MainContextProps> {
  public constructor(props: unknown) {
    super(props);

    const state: MainContextProps = {
      isError: false,
      error: '',
      results: [],
      savedGifs: [],
      setState: (partialState) => {
        this.setState((prevState) => ({
          ...prevState,
          ...partialState,
        }));
      },
    };
    this.state = state;
  }

  public render(): React.ReactNode {
    return (
      <MainContext.Provider value={this.state}>
        <MainComponent />
      </MainContext.Provider>
    );
  }
}
