import React from 'react';
import {
  Link,
  Route,
} from 'react-router-dom';
import {
  Box,
  Button,
  Container,
  Typography,
} from '@material-ui/core';

import { RoutePath } from 'common/Types';

import { SearchComponent } from './Search/Search.component';
import { ResultsComponent } from './Results/Results.component';
import { SavedComponent } from './Saved/Saved.component';

import { MainJSS } from './Main.jss';

export const MainComponent = (): JSX.Element => {
  const classes = MainJSS();

  return (
    <Container>
      <Box className={classes.header}>
        <Button
          component={Link}
          to={RoutePath.saved}
          type="submit"
          variant="contained"
          color="primary"
          fullWidth
        >
          <Typography>View Saved Gifs</Typography>
        </Button>
        <SearchComponent />
      </Box>
      <Box>
        <Route exact path={RoutePath.results} component={ResultsComponent} />
        <Route exact path={RoutePath.saved} component={SavedComponent} />
      </Box>
    </Container>
  );
};
