import React, {
  useContext,
  useState,
} from 'react';
import {
  Grid,
  Card,
  CardActionArea,
  CardMedia,
  Dialog,
} from '@material-ui/core';

import { Gif } from 'common/Models';

import { MainContext } from '../Main.context';

export const SavedComponent = (): JSX.Element => {
  const mainContext = useContext(MainContext);
  const [open, setOpen] = useState(false);
  const [displayImage, setDisplayImage] = useState('');

  const handleClickOpen = (url: string): void => {
    setOpen(true);
    setDisplayImage(url);
  };
  const handleClose = (): void => {
    setOpen(false);
  };

  return (
    <Grid container spacing={4}>
      {mainContext.savedGifs.map((result: Gif) => (
        <Grid item md={2} key={result.id}>
          <Card>
            <CardActionArea>
              <CardMedia
                component="img"
                height={200}
                image={result.images.fixed_height.url}
                onClick={() => handleClickOpen(result.images.original.url)}
              />
            </CardActionArea>
          </Card>
        </Grid>
      ))}
      <Dialog onClose={handleClose} open={open}>
        <Card>
          <CardActionArea>
            <CardMedia
              component="img"
              image={displayImage}
            />
          </CardActionArea>
        </Card>
      </Dialog>
    </Grid>
  );
};
