import React, { useContext } from 'react';
import {
  Box,
  Grid,
  Card,
  CardActionArea,
  CardMedia,
  CardActions,
  Button,
  Typography,
} from '@material-ui/core';

import { Gif } from 'common/Models';

import { MainContext } from '../Main.context';

export const ResultsComponent = (): JSX.Element => {
  let display;
  const mainContext = useContext(MainContext);
  const {
    savedGifs,
  } = mainContext;

  const isSaved = (result: Gif): boolean => {
    const unique = mainContext.savedGifs.filter((gif) => gif.id === result.id);
    if (unique.length === 0) {
      return false;
    }
    return true;
  }

  const saveImage = (result: Gif): void => {
    if (!isSaved(result)) {
      mainContext.setState({ savedGifs: [...savedGifs, result] });
    }
  };

  if (mainContext.isError) {
    display = (
      <Box>
        <Typography>
          An Error Occurred
        </Typography>
      </Box>
    );
  } else {
    display = (
      <Grid container spacing={4}>
        {mainContext.results.map((result: Gif) => (
          <Grid key={result.id} item md={2}>
            <Card>
              <CardActionArea>
                <CardMedia
                  component="img"
                  height={200} // The gif results are cutting off in terms of width, will tinker to optimize if time permits
                  image={result.images.fixed_height.url}
                  onClick={() => saveImage(result)}
                />
              </CardActionArea>
              <CardActions>
                <Button
                  size="medium"
                  color="primary"
                  onClick={() => saveImage(result)}
                >
                  {isSaved(result) ? 'Saved!' : 'Save!'}
                </Button>
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>
    );
  }

  return (
    <>
      {display}
    </>
  );
};
